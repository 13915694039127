.RoleMgt .controls select {
  width: 262px;
}
.RoleMgt .controls .button {
  margin-left: 10px;
}
.RoleMgt .controls .CustomInput {
  width: 262px;
}
.RoleMgt .controls .button {
  margin-left: 10px;
}
.RoleMgt .controls .button.save {
  min-width: 64px;
}
