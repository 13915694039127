.radio {
    width: 16px;
    height: 16px;
    position: relative;
    background: #fff url(../../assets/images/icon_radio_off.png) center no-repeat;
    display: inline-block;
    cursor: pointer;
}

.radio input {
    opacity: 0;
    margin: 0;
    cursor: pointer
}

.radio.checked {
    background: url(../../assets/images/icon_radio_on.png) center no-repeat;
}
