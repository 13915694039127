.CustomInput {
    border: 1px solid #dcdcdc;
    padding: 9px 19px;
    border-radius: 10px
}
.CustomInput::placeholder {
    color: #767676
}

.CustomInput:-ms-input-placeholder {
    color: #767676
}

.CustomInput:focus {
    border: 1px solid #1a2f80
}
.CustomInput.warn {
    border-color:#f43131
}
