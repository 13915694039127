.select {
  border: 1px solid #dcdcdc;
  padding: 9px 0 9px 15px;
  min-width: 180px;
  border-radius: 10px;
}

.date {
  padding: 9px 0 9px 30px;
  background: url(../../assets/images/icon_calendar.png) no-repeat 10px;
}
.select.warn {
    border-color: #f43131
}
