.UploadList {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  padding: 0 20px;
}

.UploadList .file_sc {
  display: block;
  margin-bottom: 15px;
  color: #767676;
}

.UploadList .file_name li {
  width: 33%;
  display: inline-block;
  margin: 15px 0;
  color: #a2a2a7;
  font-size: 12px;
}

.UploadList .file_name img {
  margin-right: 5px;
  vertical-align: top;
}

.UploadList .file_name span {
  font-size: 16px;
  color: #111;
  display: inline-block;
  width: 68.9%;
}

.UploadList .file_name img.file_del_btn {
  margin-right: 0;
  margin-left: 10px;
  vertical-align: middle;
}
