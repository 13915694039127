.DeliveryTxn h3.page_tit {
  margin-bottom: 40px;
}
/*FileUpload Process*/
.DeliveryTxn .FileUploadProcess ul.upload_step {
  width: 100%;
  display: table;
}
.DeliveryTxn .FileUploadProcess ul.upload_step li {
  display: table-cell;
  width: 33%;
}

.DeliveryTxn .FileUploadProcess ul.upload_step li:first-child {
  text-align: left;
}

.DeliveryTxn .FileUploadProcess ul.upload_step li:nth-child(2) {
  text-align: center;
}
.DeliveryTxn .FileUploadProcess ul.upload_step li:last-child {
  text-align: right;
}
.DeliveryTxn .FileUploadProcess ul.upload_step li a {
  color: #505050;
  display: inline-block;
  position: relative;
}
.DeliveryTxn .FileUploadProcess ul.upload_step li.on a {
  font-weight: 500;
  color: #0084ff;
}
.DeliveryTxn .FileUploadProcess .StepBar {
  height: 4px;
  background: #0084ff;
  margin-top: 17px;
}
.DeliveryTxn .FileUploadProcess .StepBar.Step1on {
  width: 28.2%;
  border-radius: 0 3px 3px 0;
}
.DeliveryTxn .FileUploadProcess .StepBar.Step2on {
  /* width: 55%; */
  width: 60%;
  border-radius: 0 3px 3px 0;
}
.DeliveryTxn .FileUploadProcess .StepBar.Step3on {
  width: 100%;
  border-radius: inherit;
}
.DeliveryTxn .UploadArea {
  width: 100%;
  height: 300px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 20px;
  margin-top: -1px;
  background: #fff;
}

.DeliveryTxn .UploadArea .drop {
  border: 1px dashed #b3b3b3;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0;
  background: #fafafa;
}

.DeliveryTxn .UploadArea .icon {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.DeliveryTxn .UploadArea h4 {
  font-weight: 400;
  margin-top: 15px;
}
.DeliveryTxn .UploadArea .drop.SecondStep {
  height: 100px;
  padding: 20px 40px;
  text-align: left;
}

.DeliveryTxn .UploadArea .drop.SecondStep .icon {
  display: inline-block;
  margin: inherit;
  margin-right: 40px;
}

.DeliveryTxn .UploadArea .drop.SecondStep .fee_file_upload {
  float: right;
}

.DeliveryTxn .UploadArea .drop.ThirdStep .button {
  margin: 0 10px;
}
.DeliveryTxn .UploadArea .drop.ThirdStep h4 {
  font-weight: 400;
  margin-top: 15px;
  color: #f43131;
}

.DeliveryTxn .Example{
  margin-top:20px
}
.DeliveryTxn .Example h5{
  font-weight: 500;
  color:#0084ff;
  font-size: 14px;
}
.DeliveryTxn .Example span{
  display: block;
  margin-top: 10px;
  font-size:14px;
  line-height:22px;
}