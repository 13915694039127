.RmtFeeSmryCorpDetails .condition_search table td dd.Double {
  position: relative;
}
.RmtFeeSmryCorpDetails .condition_search table td dl {
  width: 40%;
}
.RmtFeeSmryCorpDetails .condition_search table td:first-child dl:first-child {
  width: 50%;
}
.RmtFeeSmryCorpDetails
  .condition_search
  table
  td:first-child
  dl:first-child
  dd {
  padding-right: 2.7%;
}
.RmtFeeSmryCorpDetails .condition_search table td dl .react-datepicker-wrapper {
  width: 47.7%;
}
.RmtFeeSmryCorpDetails
  .condition_search
  table
  td
  dl
  .react-datepicker-wrapper
  .react-datepicker__input-container
  > input {
  width: 100%;
  padding-left: 30px;
  background-position-x: 7px;
}
.RmtFeeSmryCorpDetails
  .condition_search
  .react-datepicker__tab-loop:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 17% !important;
}
@media all and (max-width: 1590px) {
  .RmtFeeSmryCorpDetails .condition_search table td:first-child {
    width: 70%;
  }
  .RmtFeeSmryCorpDetails .condition_search table td dl {
    width: 50%;
  }
}
@media all and (max-width: 1450px) {
}
