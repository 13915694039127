.PauseRmvListPopupDown .popup_grid {
  width: 450px;
}
.PauseRmvListPopupDown .popup_grid td .CustomInput {
  width: 100%;
}

.PauseRmvListPopupDown .popup_grid th,
.PauseRmvListPopupDown .popup_grid td {
  padding: 0 10px;
  text-align: center;
}

.PauseRmvListPopupDown .LayerPopup .popup_grid {
  overflow-y: inherit;
}
