/*Step Process*/
.SetlTxn .StepProcess {
  width: 100%;
  display: table;
  margin-bottom: 80px;
}

.SetlTxn .StepProcess li {
  display: table-cell;
  text-align: center;
}

.SetlTxn .StepProcess li:first-child {
  width: 19%;
}

.SetlTxn .StepProcess li.arrow {
  width: 9%;
  vertical-align: middle;
}

.SetlTxn .StepProcess span {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  line-height: 44px;
  font-size: 20px;
  color: #111;
  border: 1px solid #111;
  margin: auto;
  margin-bottom: 20px;
  font-weight: 500;
}

.SetlTxn .StepProcess li.on span {
  background: #111;
  color: #fff;
}

.SetlTxn .StepProcess li.on h3 {
  color: #1a2f80;
}

.SetlTxn .StepProcess h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.SetlTxn .StepProcess h4 {
  font-weight: 400;
  color: #505050;
  line-height: 20px;
}

.SetlTxn .UploadArea {
  width: 100%;
  height: 300px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;
  background: #fff;
}

.SetlTxn .UploadArea .drop {
  border: 1px dashed #b3b3b3;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0;
  background: #fafafa;
}

.SetlTxn .UploadArea .icon {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.SetlTxn .UploadArea h4 {
  font-weight: 400;
  margin-top: 15px;
}
.SetlTxn .UploadArea h4 span {
  color: #f43131;
}
.SetlTxn .text_info {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.SetlTxn .text_info p {
  color: #505050;
  font-weight: 300;
  position: relative;
  padding-left: 12px;
  margin-top: 12px;
}
.SetlTxn .text_info p:before {
  content: "";
  width: 4px;
  height: 4px;
  background: #929298;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.SetlTxn .text_info p > span {
  color: #f43132;
}
.SetlTxn .text_info p > span.line {
  width: 1px;
  height: 10px;
  background: #767676;
}
.SetlTxn .text_info p > img.line {
  margin: -2px 5px 0 5px;
}
.SetlTxn .text_info p > img {
  margin-top: -2px;
}
.SetlTxn .text_info a > span {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 1px solid #111;
  color: #111;
}
.SetlTxn .text_info a > img {
  margin: -2px 0 0 8px;
}
.SetlTxn .dateList{
  display: block;
  width: 400px;
  margin: auto;
  margin-bottom: 35px;

}
.SetlTxn .dateList li{
  display:inline-block;
  font-size:16px;
  color:#767676;
  font-weight: 500;
  width:auto;
}
.SetlTxn .dateList li.on{
  color:#0084ff
}
.SetlTxn .dateList li.line{
  margin:0 3px;
  height:1px;
  background: none;
  vertical-align: top;
}
.SetlTxn .dateList li img.on{
  display:none;
  margin-right: 5px;
}
.SetlTxn .dateList li.on img.on{
  display: inline-block;
}