.checkbox {
  width: 20px;
  height: 19px;
  position: relative;
  position: relative;
  background: url(../../assets/images/icon_check_off.png) no-repeat;
  display: inline-block;
  cursor: pointer;
}
.checkbox input {
  opacity: 0;
  margin: 0;
  cursor: pointer;
}
.checkbox.checked {
  background: url(../../assets/images/icon_check_on.png) no-repeat;
}
