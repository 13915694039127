.PauseRmvList .grid_data .tbody {
  resize: none;
}

.PauseRmvList .grid_data > div {
  min-width: 2400px;
}
.PauseRmvList .grid_data {
  resize: vertical;
}
.PauseRmvList .grid_data .holdTable { 
  max-height: 400px;
  width: 2418px;
  overflow-y: scroll;  
  overflow-x: hidden;
}