.DpstTxn .tab_area {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.DpstTxn .tab_area td {
  color: #767676;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}

.DpstTxn .tab_area td:first-child a {
  border-top-left-radius: 5px;
  border-left: 1px solid #dcdcdc;
}

.DpstTxn .tab_area td.last a {
  border-top-right-radius: 5px;
}

.DpstTxn .tab_area td a {
  display: block;
  padding: 23px 0;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  background: #fafafa;
}

.DpstTxn .tab_area td img {
  display: none;
  margin-right: 5px;
}

.DpstTxn .tab_area td.on {
  border-bottom: none;
}

.DpstTxn .tab_area td.on a {
  background: #fff;
  color: #1a2f80;
  font-weight: 500;
}

.DpstTxn .tab_area td.on img {
  display: inline-block;
}
.DpstTxn .controls .Bankbook {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  text-decoration: underline;
}
.DpstTxn .controls .Bankbook img {
  margin-right: 6px;
}
.DpstTxn .DpstTxnTable .thead.HasTD th {
  border-right: 1px solid #dcdcdc;
}
.DpstTxn .DpstTxnTable .thead.HasTD th:last-child {
  border-right: none;
}
.DpstTxn .DpstTxnEnterPopup {
  width: 930px;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid {
  border-bottom: none;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid th {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .secondTB {
  max-height: 245px;
  overflow-x: hidden;
  overflow-y: auto;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .secondTB table {
  border-top: 2px solid #111;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid td {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .btn_area {
  text-align: right;
  margin-top: 40px;
  margin-bottom: 10px;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .secondTB button {
  width: 90% !important;
  min-width: 90% !important;
  padding: 0;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .secondTB input[type="text"] {
  width: 90% !important;
}
.DpstTxn .DpstTxnEnterPopup .popup_grid .secondTB .RegID {
  color: #f43131;
}
.DpstTxn .controls .select {
  width: 260px;
}
