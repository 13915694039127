.AddressSearch {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  display: none;
}
.SearchArea {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 40px;
  width: 500px;
  z-index: 20;
}
.PopupClose {
  position: absolute;
  right: 13px;
  top: 12px;
  cursor: pointer;
}

.PopupBG {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}
.AddressSearch h3.page_tit {
  font-size: 24px;
  margin-bottom: 30px;
}
.AddressSearch h5 {
  color: #505050;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
.AddressSearch dl {
  display: inline-block;
  margin-top: 30px;
  width: 100%;
}
.AddressSearch dt {
  display: inline-block;
  position: relative;
}
.AddressSearch dt .CustomInput {
  width: 340px;
  float: left;
}
.AddressSearch dt span.del {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.AddressSearch dd {
  float: right;
}
.AddressSearch dd .button {
  min-width: 70px;
  color: #fff;
  background: #1a2f80;
  border: 1px solid #1a2f80;
}
.AddressSearch h4 {
  font-size: 14px;
  color: #1a2f80;
  margin-bottom: 10px;
}
.AddressSearch .TipArea {
  margin: 40px 0 275px 0;
  /* display: none; */
}
.AddressSearch .TipArea table {
  border-top: 2px solid #111;
  border-bottom: 1px solid #dcdcdc;
}
.AddressSearch .TipArea th {
  padding: 6px 0 6px 20px;
  color: #505050;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  background-color: #fafafa;
}
.AddressSearch .TipArea td {
  padding: 6px 0 6px 20px;
  color: #111;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  background-color: #fafafa;
}
.AddressSearch tr:first-child th,
.AddressSearch tr:first-child td {
  padding-top: 20px;
}
.AddressSearch tr:last-child th,
.AddressSearch tr:last-child td {
  padding-bottom: 20px;
}

.AddressSearch .help {
  text-align: center;
  font-size: 14px;
  color: #767676;
  line-height: 22px;
}
.AddressSearch .help a {
  color: #1a2f80;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px solid #1a2f80;
}
.AddressSearch .ResultArea {
  margin-top: 25px;
}
.AddressSearch .ResultArea .HasScroll {
  border-top: 2px solid #111;
  border-bottom: 1px solid #dcdcdc;
  max-height: 300px;
  overflow-y: auto;
  padding: 0 20px;
  background-color: #fafafa;
}
.AddressSearch .ResultArea th {
  text-align: left;
  font-size: 14px;
  color: #111;
  line-height: 22px;
  padding: 6px 0;
  font-weight: 400;
  border-bottom: 1px solid #dcdcdc;
}
.AddressSearch .ResultArea th span {
  color: #1a2f80;
  font-weight: 500;
}
.AddressSearch .ResultArea .lotNum {
  color: #505050;
  margin-top: 8px;
}
.AddressSearch .ResultArea td {
  text-align: right;
  font-size: 14px;
  color: #111;
  border-bottom: 1px solid #dcdcdc;
}
.AddressSearch .ResultArea tr:last-child th,
.AddressSearch .ResultArea tr:last-child td {
  border-bottom: none;
}

.AddressSearch .ResultArea .paging {
  margin: 15px 0 40px 0;
}
