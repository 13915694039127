
.CarInfo .controls .button {
  margin-left: 10px;
}
.CarInfo .LayerPopup .PopupArea .info_text {
  /* display: block; */
  padding-left: 0 !important;
  margin-top: 10px;
  color: #767676;
  font-weight: 300;
}
.CarInfo .LayerPopup .PopupArea .has_checkbox .checkbox {
  margin: 0 10px 0 20px;
}

.CarInfo .LayerPopup .PopupArea .TextColorRed {
  color: #f43131;
}
