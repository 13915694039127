.title {
  position: relative;
}

h3.page_tit {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 40px;
}

h3.page_tit span {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #767676;
  vertical-align: middle;
  font-weight: 400;
}

h3.page_tit span.dep1 {
  margin-left: 10px;
  padding-left: 10px;
}

h3.page_tit span.dep1:before {
  content: "";
  width: 1px;
  height: 16px;
  background: #dcdcdc;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

h3.page_tit span.dep2 {
  margin-left: 10px;
  padding-left: 15px;
}

h3.page_tit span.dep2:before {
  content: ">";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-size: 11px;
  font-family: "돋움", Dotum;
  font-weight: bold;
}

.add_content {
  position: absolute;
  right: 0;
  top: 0;
}
