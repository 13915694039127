/*
.controls {
  width: 100%;
  position: relative;
  text-align: right;
  margin-bottom: 16px;
}

.select_list {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
 */

.condition_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  background: #f7f7f7;
  border-radius: 10px;
  text-align: left;
}
.condition_search .HasTable {
  padding: 35px 0 35px 2.7%;
}
.condition_search form {
  width: 100%;
}
.condition_search table td:first-child {
  border-right: 1px solid #dcdcdc;
  width: 78.8%;
}
.condition_search table td:nth-child(2) {
  text-align: center;
  padding: 0 2.8%;
}
.condition_search table td:nth-child(2) .button {
  width: 100%;
  padding: 0;
}
.condition_search table td dl {
  display: inline-table;
  width: 33.3%;
}
.condition_search table td dt {
  display: table-cell;
  width: 80px;
}
.condition_search table td dd {
  display: table-cell;
  padding: 5px 8.7% 5px 0;
}
.condition_search table td dd div.HasDate{
    position:relative;
    display:inline-block;
    width:100%
}
.condition_search table td dd div.HasDate .react-datepicker-wrapper{
   width:100%
}
.condition_search table td dd div.HasDate .react-datepicker__input-container>input{
    width:100%
}

.condition_search table td dd .CustomInput,
.condition_search table td dd .CacheAutocomplete input,
.condition_search table td dd .select {
  width: 100%;
  min-width: 100%;
}
.condition_search table td dd .CacheAutocomplete {
  width: 100%;
}
.condition_search table td dt i::after {
  content: "*";
  color: #f43131;
}
.condition_search table td dt span {
  display: block;
  color: #f43131;
  margin-top: 4px;
}
.condition_search table td dd.Double .select,
.condition_search table td dd.Double .CustomInput {
  width: 49.2%;
  min-width: 49.2%;
}
.condition_search table td dd.Double .select:first-child,
.condition_search table td dd.Double .CustomInput:first-child {
  margin-right: 1.5%;
}
.condition_search .react-datepicker-popper{
  left:50%!important;
  margin-left:-121px
}

.condition_search .react-datepicker-popper .react-datepicker__triangle{
  left:50%!important;
  margin-left:-6px
}
.condition_search table td dd>div{
  width:100%
}


@media all and (max-width:1350px) {
    .condition_search table td:first-child {
        width: 70%
    }
    .condition_search table td dl {
        width: 50%
    }
}