.Nav {
  height: 60px;
  border-bottom: 1px solid #ededed;
  background: #eff3fb;
}

.Nav ul {
  width: 100%;
  display: table;
}

.Nav li {
  display: table-cell;
  /* width: 16.6%; */
  /* width: auto; */
  width: 14.2%;
}

.Nav li a {
  display: block;
  line-height: 60px;
  text-align: center;
  position: relative;
}

.Nav li a img {
  margin-right: 10px;
}

.Nav li a img.on {
  display: none;
}

.Nav li a:hover,
.Nav li a.on {
  background: #5674ed;
  color: #fff;
  border-radius: 5px;
}

.Nav li a:hover img.on,
.Nav li a.on img.on {
  display: inline-block;
}

.Nav li a:hover img.off,
.Nav li a.on img.off {
  display: none;
}

.Nav li:first-child a:hover,
.Nav li:first-child a.on {
  border-radius: 0 5px 5px 0;
}

.Nav li:last-child a:hover,
.Nav li:last-child a.on {
  border-radius: 5px 0 0 5px;
}

.Nav li a:hover:before,
.Nav li a.on:before {
  content: "";
  width: 12px;
  height: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: url(../assets/images/nav_arrow.png) no-repeat;
  bottom: -7px;
}
