.SetlSumTxnTable .grid_data .thead th {
  border-right: 1px solid #dcdcdc;
}
.SetlSumTxnTable .grid_data .thead th:last-child {
  border-right: none;
}
.SetlSumTxnTable .grid_data .thead td {
  border: 1px solid #dcdcdc;
  border-bottom: none;
}
.SetlSumTxnTable .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.SetlSumTxnTable .grid_data .tfoot td {
  color: #f43131;
}
.SetlSumTxnTable .grid_data .tfoot th {
  border-bottom: 1px solid #ededed;
  background: #eef3f9;
  font-weight: 400;
}
.SetlSumTxnTable .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}

.SetlSumTxnTable .grid_data .thead,
.SetlSumTxnTable .grid_data .tbody,
.SetlSumTxnTable .grid_data .tfoot {
  width: 2200px;
  resize: none;
}

.SetlSumTxnTable .grid_data .holdTable {
  max-height: 400px;
  width: 2216px; 
  overflow-y: scroll;
  overflow-x: hidden;
  /* -ms-overflow-style: none;  */
}
/* .SetlSumTxnTable .grid_data .holdTable::-webkit-scrollbar { 
  display: none !important; 
} */