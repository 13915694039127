.FeeInfoSpecial Form {
  width: 100%;
}
.FeeInfoSpecial .controls i::after {
  content: "*";
  color: #f43131;
}
.FeeInfoSpecial .controls span {
  /* display: block; */
  color: #f43131;
  margin-top: 4px;
}
.FeeInfoSpecial .controls .button {
  margin-left: 10px;
}
.FeeInfoSpecial .left_tb {
  width: 22.7% !important;
  float: left;
  min-width: 22.7%;
}
.FeeInfoSpecial .right_tb {
  width: 74.8% !important;
  float: right;
  min-width: 74.8%;
}
.FeeInfoSpecial .grid_data .tbody .CustomInput {
  width: 90%;
}
.FeeInfoSpecial .grid_data .tbody .btn {
  text-align: right;
}
.FeeInfoSpecial .grid_data .tbody .btn .delete {
  min-width: 65px;
}
