.RptDatPrtRoute .controls .left i::after {
  content: "*";
  color: #f43131;
}
.RptDatPrtRoute .controls .left span {
  /* display: block; */
  color: #f43131;
  margin-top: 4px;
}

.RptDatPrtRoute Form {
  width: 100%;
}
.RptDatPrtRoute .controls .info_text {
  font-size: 14px;
  color: #505050;
}
.RptDatPrtRoute .tb_area {
  width: 100%;
  display: inline-block;
}
.RptDatPrtRoute .tb_area .thead th {
  font-weight: normal;
  height: 60px;
  text-align: center;
}
.RptDatPrtRoute .tb_area .left {
  width: 49.3%;
  float: left;
}
.RptDatPrtRoute .tb_area .right {
  width: 49.3%;
  float: right;
}
.RptDatPrtRoute .tb_area .tbody td {
  text-align: right;
  padding-right: 20px;
}
.RptDatPrtRoute .tb_area .tbody td.text {
  text-align: left;
  padding-left: 20px;
}
.RptDatPrtRoute .tb_area .tbody td .button {
  margin-left: 10px;
}
