.DashBoard .Notice {
  width: 100%;
  display: inline-block;
  padding: 20px 0;
  text-align: left;
  border-bottom: 1px solid #ededed;
}
.DashBoard .Notice ul {
  display: inline-block;
}
.DashBoard .Notice li {
  display: inline-block;
}
.DashBoard .Notice li.type {
  font-size: 16px;
  color: #1a2f80;
  font-weight: 500;
}
.DashBoard .Notice li.cnt {
  font-size: 16px;
  color: #505050;
  margin-left: 7px;
}
.DashBoard .Notice li.writingDate {
  font-size: 16px;
  color: #505050;
  margin-left: 20px;
}
.DashBoard .Notice .control {
  float: right;
}
.DashBoard .Notice .control a {
  display: inline-block;
  margin: 0 5px;
}
.DashBoard .Notice .pointer {
  cursor: pointer;
}
.DashBoard .GraphArea {
  width: 100%;
  display: inline-block;
  padding-bottom: 60px;
  border-bottom: 1px solid #ededed;
  margin-top: 40px;
}
.DashBoard .GraphArea .titleArea {
  position: relative;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 30px;
}
.DashBoard .GraphArea .titleArea a {
  position: absolute;
  top: 0;
  right: 0;
}
.DashBoard .GraphArea h3 {
  font-size: 24px;
  font-weight: 500;
}
.DashBoard .GraphData {
  float: left;
}
.DashBoard .GraphData.first {
  margin-right: 2%;
}
.DashBoard .GraphData.w50 {
  width: 48%;
}
.DashBoard .GraphData .Graph {
  width: 100%;
  height: 270px;
}
.DashBoard .GraphData .Graph .chartjs-render-monitor {
  height: 270px !important;
}
.DashBoard .GraphData .Graph .chart {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}
.DashBoard .GraphData .Graph img {
  width: 100%;
  vertical-align: bottom;
}
.DashBoard .GraphData .Graph.leftArea {
  width: 76%;
  float: left;
}
.DashBoard .sortation {
  display: inline-block;
  margin-top: 25px;
  font-weight: 400;
  color: #505050;
  text-align: center;
  width: 100%;
}
.DashBoard .sortation li {
  display: inline-block;
  margin: 0 8px;
}
.DashBoard .sortation li span {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 2px;
}
.DashBoard .sortation li span.total {
  width: 30px;
}
.DashBoard .sortation li span.blue {
  background-color: #3e64f4;
}
.DashBoard .sortation li span.green {
  background-color: #34bca1;
}
.DashBoard .sortation li span.yellow {
  background-color: #fbba0a;
}
.DashBoard .sortation li span.red {
  background-color: #fd6f4f;
}
.DashBoard .TotalFare {
  display: inline-block;
  padding: 0 1.3%;
  background-color: #f7f7f7;
  border-radius: 10px;
  width: 22%;
  float: right;
}
.DashBoard .TotalFare table {
  width: 100%;
}
.DashBoard .TotalFare th {
  font-weight: 400;
  color: #505050;
  text-align: left;
  border-bottom: 1px solid #fff;
  padding: 22px 0;
}
.DashBoard .TotalFare th span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #111;
  margin-top: 14px;
  letter-spacing: -1px;
}
.DashBoard .TotalFare td {
  font-weight: 400;
  text-align: right;
  border-bottom: 1px solid #fff;
  padding: 22px 0;
}
.DashBoard .TotalFare td.down {
  color: #f83434;
}
.DashBoard .TotalFare td.up {
  color: #1a47f6;
}
.DashBoard .TotalFare td.same {
  color: #767676;
}
.DashBoard .TotalFare td img {
  margin: -4px 5px 0 0;
}
.DashBoard .Graph .red {
  background-color: #fd6f4f;
}
.DashBoard .Graph .blue {
  background-color: #3e64f4;
}
.DashBoard .Graph .yellow {
  background-color: #fbba0a;
}
.DashBoard .Graph .green {
  background-color: #34bca1;
}
.DashBoard .Graph .gray {
  background-color: #34bca1;
}
