.DsblTxnDetailsTable .grid_data .thead,
.DsblTxnDetailsTable .grid_data .tbody {
  width: 2000px;
}
.DsblTxnDetails .condition_search table td dd.Double {
  position: relative;
}
.DsblTxnDetails .condition_search table td:first-child dl:first-child {
  width: 50%;
}
.DsblTxnDetails .condition_search table td:first-child dl:first-child dd {
  padding-right: 2.7%;
}
.DsblTxnDetails .condition_search table td dl .react-datepicker-wrapper {
  width: 47.7%;
}
.DsblTxnDetails
  .condition_search
  table
  td
  dl
  .react-datepicker-wrapper
  .react-datepicker__input-container
  > input {
  width: 100%;
  padding-left: 30px;
  background-position-x: 7px;
}
.DsblTxnDetails .condition_search .react-datepicker__tab-loop:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 17% !important;
}
.DsblTxnDetails .grid_data .holdTable { 
  max-height: 400px;
  width: 2017px;
  overflow-y: scroll;  
  overflow-x: hidden;
}