.DailyFeeSumDetailsTable .grid_data .thead,
.DailyFeeSumDetailsTable .grid_data .tbody {
  width: 2000px;
}

.DailyFeeSumDetailsTable .grid_data .holdTable {
  max-height: 400px;
  width: 2017px;
  overflow-y: scroll;
  overflow-x: hidden;
}