.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup {
  width: 1305px;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid {
  border-bottom: none;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid th {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid .secondTB {
  max-height: 245px;
  overflow-x: hidden;
  overflow-y: auto;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid .secondTB table {
  border-top: 2px solid #111;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid td {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid td.amount {
  text-align: right;
  padding-right: 10;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid td.tal {
  text-align: left;
  padding-left: 10px;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid .btn_area {
  text-align: right;
  margin-top: 40px;
  margin-bottom: 10px;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid .secondTB button {
  width: 90% !important;
  min-width: 90% !important;
  padding: 0;
}
.RmtFeeSmryCorpList
  .RmtFeeSmryCorpListEnterPopup
  .popup_grid
  .secondTB
  input[type="text"] {
  width: 90% !important;
}
.RmtFeeSmryCorpList
  .RmtFeeSmryCorpListEnterPopup
  .popup_grid
  .secondTB
  input[type="number"] {
  width: 90% !important;
}
.RmtFeeSmryCorpList
  .RmtFeeSmryCorpListEnterPopup
  .popup_grid
  .secondTB
  .select {
  width: 90% !important;
  min-width: 90% !important;
}
.RmtFeeSmryCorpList .RmtFeeSmryCorpListEnterPopup .popup_grid .secondTB .RegID {
  color: #f43131;
}
.RmtFeeSmryCorpList .condition_search table td dd.Double {
  position: relative;
}
.RmtFeeSmryCorpList .condition_search table td dl {
  width: 40%;
}
.RmtFeeSmryCorpList .condition_search table td:first-child dl:first-child {
  width: 50%;
}
.RmtFeeSmryCorpList .condition_search table td:first-child dl:first-child dd {
  padding-right: 2.7%;
}
.RmtFeeSmryCorpList .condition_search table td dl .react-datepicker-wrapper {
  width: 47.7%;
}
.RmtFeeSmryCorpList
  .condition_search
  table
  td
  dl
  .react-datepicker-wrapper
  .react-datepicker__input-container
  > input {
  width: 100%;
  padding-left: 30px;
  background-position-x: 7px;
}
.RmtFeeSmryCorpList .condition_search .react-datepicker__tab-loop:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 17% !important;
}

.Double > div.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: -29%;
}

@media all and (max-width: 1590px) {
  .RmtFeeSmryCorpList .condition_search table td:first-child {
    width: 70%;
  }
  .RmtFeeSmryCorpList .condition_search table td dl {
    width: 50%;
  }
}
@media all and (max-width: 1450px) {
}
