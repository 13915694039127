.SetlTxnStats .condition_search .CustomInput {
  width: 160px;
  margin-right: 20px;
}
.SetlTxnStats .condition_search .select {
  margin-right: 20px;
}
.SetlTxnStats .grid_data > div {
  min-width: 7000px;
}
.SetlTxnStats .grid_data .tbody {
  resize: none;
}
.SetlTxnStats .grid_data {
  resize: vertical;
}
