.grid_data .tfoot .total td {
  color: #f43131;
}

.grid_data .tfoot .total td:first-child {
  color: #111;
  background: #eef3f9;
}

.grid_data .tfoot .percentage td {
  color: #336ee0;
}

.grid_data .tfoot .percentage td:first-child {
  color: #111;
  background: #eef3f9;
}
