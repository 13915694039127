.PlcfbList .PlcfbListPopup .popup_grid {
  width: 1250px;
}
.PlcfbList .PlcfbListPopup .popup_grid th {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.PlcfbList .PlcfbListPopup .popup_grid td.amount {
  text-align: right;
  padding-right: 10px;
}
.PlcfbList .PlcfbListPopup .popup_grid td {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.PlcfbList .PlcfbListPopup .popup_grid td.amount {
  text-align: right;
  padding-right: 10px;
}
.PlcfbList .PlcfbListPopup .popup_grid td.tal {
  text-align: left;
  padding-left: 10px;
}
