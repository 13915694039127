.NavbarLinks {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.NavbarLinks ul {
  display: inline-block;
  vertical-align: middle;
}

.NavbarLinks ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
}

.NavbarLinks ul a {
  position: relative;
  padding: 5px;
}

/* 
.NavbarLinks a:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #fc5055;
  right: 0;
  top: 0;
  position: absolute;
} */

.NavbarLinks .user {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  margin-right: 28px;
}

.NavbarLinks .user .dropdown {
  position: relative;
}

.NavbarLinks .user .now {
  width: 100%;
  position: relative;
  cursor: pointer;
  border-right:1px solid #0f2370
}

.NavbarLinks .user .name_id {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 40px;
  text-align: left;
}

.NavbarLinks .user .name_id span {
  display: block;
  margin:6px 0
}

.NavbarLinks .user .name_id .name {
  font-weight: 500;
  color: #b4bcdb;
}

.NavbarLinks .user .name_id .id {
  margin-top: 3px;
  color: #fff;
}

.NavbarLinks .logout {
  cursor: pointer;
}
