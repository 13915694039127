.PlcfbAbookTxnDepositList .condition_search {
    padding: 40px;
    margin-bottom: 40px
}
.PlcfbAbookTxnDepositList .condition_search .CustomInput {
    width: 160px;
    margin-right: 20px
}
.PlcfbAbookTxnDepositList .condition_search .select{
    margin-right:20px
}

.PlcfbAbookTxnDepositList .controls .button {
    margin-left: 10px
}

