.ApdOtpayTxn .grid_data .tbody {
  resize: none;
}
.ApdOtpayTxn .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}

.ApdOtpayTxn .grid_data .tbody.HasTH {
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;  
  overflow-x: hidden;
}
