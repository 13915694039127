.RmtFeeSmryCorpListTable .grid_data .thead th {
  border-right: 1px solid #dcdcdc;
}
.RmtFeeSmryCorpListTable .grid_data .thead th:last-child {
  border-right: none;
}
.RmtFeeSmryCorpListTable .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.RmtFeeSmryCorpListTable .grid_data .tfoot td {
  color: #f43131;
}
.RmtFeeSmryCorpListTable .grid_data .tfoot th {
  border-bottom: 1px solid #ededed;
  background: #eef3f9;
  font-weight: 400;
}
.RmtFeeSmryCorpListTable .grid_data .thead,
.RmtFeeSmryCorpListTable .grid_data .tbody,
.RmtFeeSmryCorpListTable .grid_data .tfoot {
  width: 2250px;
  resize: none;
}
.RmtFeeSmryCorpListTable .grid_data .holdTable { 
  max-height: 400px;
  width: 2267px;
  overflow-y: scroll;  
  overflow-x: hidden;
}