
.Finnc h3.page_tit {
    margin-bottom: 40px
}
/*FileUpload Process*/
.Finnc .FileUploadProcess ul.upload_step {
    width: 100%;
    display: table
}
.Finnc .FileUploadProcess ul.upload_step li {
    display:table-cell;
    width:33%
}

.Finnc .FileUploadProcess ul.upload_step li:first-child {
    text-align:left;
}

.Finnc .FileUploadProcess ul.upload_step li:nth-child(2) {
    text-align:center;
}
.Finnc .FileUploadProcess ul.upload_step li:last-child {
    text-align:right;
}
.Finnc .FileUploadProcess ul.upload_step li a {
    color: #505050;
    display: inline-block;
    position:relative
}
.Finnc .FileUploadProcess ul.upload_step li.on a {
    font-weight: 500;
    color: #0084ff;
}
.Finnc .FileUploadProcess .StepBar{
    height:4px;
    background:#0084ff;
    margin-top:17px
}
.Finnc .FileUploadProcess .StepBar.Step1on {
    width:28.2%;
    border-radius:0 3px 3px 0
}
.Finnc .FileUploadProcess .StepBar.Step2on {
    width: 55%;
    border-radius:0 3px 3px 0
}
.Finnc .FileUploadProcess .StepBar.Step3on {
    width: 100%;
    border-radius:inherit
}
.Finnc .UploadArea {
    width: 100%;
    height: 300px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    border-top-left-radius: 0;
    padding: 20px;
    margin-top: -1px;
    background:#fff
}
.Finnc .UploadArea.last {
    border-top-right-radius:0
}
.Finnc .UploadArea .drop {
    border: 1px dashed #b3b3b3;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 55px 0;
    background: #fafafa
}

.Finnc .UploadArea .icon {
    display: block;
    margin: auto;
    margin-bottom: 20px
}

.Finnc .UploadArea h4 {
    font-weight: 400;
    margin-top: 15px;
}
.Finnc .UploadArea .drop.SecondStep{
    height: 100px;
    padding: 20px 40px;
    text-align: left;
}

.Finnc .UploadArea .drop.SecondStep .icon {
    display: inline-block;
    margin: inherit;
    margin-right: 40px
}

.Finnc .UploadArea .drop.SecondStep .fee_file_upload {
    float: right
}

.Finnc .UploadList {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    padding: 0 20px;
}

.Finnc .UploadList .file_sc {
    display: block;
    margin-bottom: 15px;
    color:#767676
}

.Finnc .UploadList .file_name li {
    width: 33%;
    display: inline-block;
    margin: 15px 0;
    color: #a2a2a7;
    font-size: 12px
}

.Finnc .UploadList .file_name img {
    margin-right: 5px;
    vertical-align: top;
}

.Finnc .UploadList .file_name span {
    font-size: 16px;
    color: #111;
    display: inline-block;
    width: 68.9%
}

.Finnc .UploadList .file_name img.file_del_btn {
    margin-right: 0;
    margin-left: 10px;
    vertical-align: middle;
}
.Finnc .UploadArea .drop.ThirdStep .button {
    margin:0 10px
}
.Finnc .UploadArea .drop.ThirdStep h4 {
    font-weight: 400;
    margin-top: 15px;
    color: #f43131;
}