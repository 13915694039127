/*���ΰ���*/
.CorpInfo .condition_search .CustomInput {
  width: 180px;
}
.CorpInfo .condition_search select {
  margin-right: 20px;
}
.CorpInfo .controls .button {
  margin-left: 10px;
}
.CorpInfo .LayerPopup .PopupArea .TextColorRed {
  color: #f43131;
}
