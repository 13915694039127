.SideNavigation {
  width: 260px;
  position: fixed;
  left: 40px;
  top: 191px;
  z-index: 9;
}

.SideNavigation::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 4px;
  background: #1a2f80;
  position: absolute;
}

.SideNavigation article {
  width: 100%;
  border: 1px solid #ededed;
  padding-bottom: 40px;
  border-radius: 0 0 20px 20px;
  background: #fff;
  overflow: hidden;
}

.SideNavigation h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0 30px 20px;
}

.SideNavigation ul {
  padding-right: 39px;
}

.SideNavigation li a {
  display: block;
  line-height: 46px;
  position: relative;
}

.SideNavigation li a img {
  margin: 0 20px;
}

.SideNavigation li a img.on {
  display: none;
}

.SideNavigation li a:hover,
.SideNavigation li a.on {
  background: #f3f4f9;
  border-radius: 0 10px 10px 0;
}

.SideNavigation li a:hover img.on,
.SideNavigation li a.on img.on {
  display: inline-block;
}

.SideNavigation li a:hover img.off,
.SideNavigation li a.on img.off {
  display: none;
}

.SideNavigation li a:hover:before,
.SideNavigation li a.on:before {
  content: "";
  width: 4px;
  height: 100%;
  background: #1a2f80;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
}
