.FtasSetl .condition_search {
    padding: 40px;
    margin-bottom: 40px
}
.FtasSetl .condition_search .CustomInput{
    width:160px;
    margin-right:20px
}
.FtasSetl .condition_search .select{
    margin-right:20px
}
/*Step Process*/
.FtasSetl .StepProcess {
    width: 100%;
    display: table;
    margin-bottom: 80px
}

.FtasSetl .StepProcess li {
    display: table-cell;
    text-align: center
}

.FtasSetl .StepProcess li:first-child {
    width: 19%
}

.FtasSetl .StepProcess li.arrow {
    width: 9%;
    vertical-align: middle;
}

.FtasSetl .StepProcess span {
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    line-height: 44px;
    font-size: 20px;
    color: #111;
    border: 1px solid #111;
    margin: auto;
    margin-bottom: 20px;
    font-weight: 500
}

.FtasSetl .StepProcess li.on span {
    background: #111;
    color: #fff
}

.FtasSetl .StepProcess li.on h3 {
    color: #1a2f80
}

.FtasSetl .StepProcess h3 {
    font-weight: 400;
    margin-bottom: 10px;
}

.FtasSetl .StepProcess h4 {
    font-weight: 400;
    color: #505050;
    line-height: 20px
}

.FtasSetl .CalculateArea {
    width: 100%;
    height: 300px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    padding: 20px;
    margin-top: -1px;
    background: #fff
}

.FtasSetl .CalculateArea .drop {
    border: 1px dashed #b3b3b3;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 55px 0;
    background: #fafafa
}

.FtasSetl .CalculateArea .icon {
    display: block;
    margin: auto;
    margin-bottom: 20px
}

.FtasSetl .CalculateArea h4 {
    font-weight: 400;
    margin-top: 15px;
}
.FtasSetl .CalculateArea h4 span {
    color:#f43131
}
.FtasSetl .UploadArea .drop {
    height: 100px;
    padding: 20px 40px;
    text-align: left;
}

.FtasSetl .UploadArea .drop .icon {
    display: inline-block;
    margin: inherit;
    margin-right: 40px
}
