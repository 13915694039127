.next {
  display: inline-block;
  cursor: pointer;
}

.next img {
  margin: -3px 0 0 10px;
}

.line {
  display: inline-block;
  width: 1px;
  height: 12px;
  background: #dcdcdc;
  vertical-align: middle;
  margin: -3px 20px 0 20px;
}
