.tab_area {
  width: 100%;
  margin: 60px 0 40px 0;
}

.tab_area td {
  color: #767676;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}

.tab_area td:first-child span {
  border-top-left-radius: 5px;
  border-left: 1px solid #dcdcdc;
}

.tab_area td.last span {
  border-top-right-radius: 5px;
}

.tab_area td span {
  display: block;
  padding: 23px 0;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  background: #fafafa;
  cursor: pointer;
}

.tab_area td img {
  display: none;
  margin-right: 5px;
}

.tab_area td.on {
  border-bottom: none;
}

.tab_area td.on span {
  background: #fff;
  color: #1a2f80;
  font-weight: 500;
}

.tab_area td.on img {
  display: inline-block;
}
