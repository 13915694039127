.LayerPopup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: none;
}
.PopupArea {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 40px;
  z-index: 20;
}
.PopupClose {
  position: absolute;
  right: 13px;
  top: 12px;
  cursor: pointer;
}

.PopupBG {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}
.LayerPopup h3.page_tit {
  font-size: 24px;
  margin-bottom:20px
}
.LayerPopup .PopupButton {
  text-align: center;
  margin-top: 40px;
}
.LayerPopup .PopupButton .button {
  margin: 0 5px;
}
.LayerPopup .popup_grid {
  border-top: 2px solid #111;
  border-bottom: 1px solid #dcdcdc;
  max-height:500px;
  overflow-y:auto
}

.LayerPopup .popup_grid th {
  text-align: left;
  background: #eef3f6;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  padding-left: 20px;
  font-weight: 400;
  height: 60px;
}

.LayerPopup .popup_grid td {
  text-align: left;
  border-bottom: 1px solid #ededed;
  padding: 10px 20px;
  height: 60px;
  font-size: 14px;
}
.LayerPopup .popup_grid td p:first-child {
  margin-bottom: 10px;
}

.LayerPopup .popup_grid tr:last-child th {
  border-bottom: none;
}

.LayerPopup .popup_grid tr:last-child td {
  border-bottom: none;
}
.LayerPopup .PopupArea .PopupMessage {
  margin: 20px 0 40px 0;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.LayerPopup .PopupArea form {
  width: 100%;
}
.LayerPopup .popup_grid .PopupDate{
    width:100%;
    display:table;
    margin-top:15px
}
.LayerPopup .popup_grid .PopupDate > ul {
    width:45%;
    display:table-cell;
    vertical-align:middle
}
.LayerPopup .popup_grid .PopupDate > ul > li > span{
    display:block;
    margin-top:5px;
    color:#f43131;
    margin-left:20px;
}
.LayerPopup .popup_grid .PopupDate > .termIcon {
    width:10%;
    display:table-cell;
    vertical-align:top;
    text-indent:-3px;
    text-align:center;
    line-height:39px
}
.LayerPopup .popup_grid .PopupDate>ul input{
    width:100%;
    background-position-x:8%
}
.LayerPopup h5 {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 10px
}
.LayerPopup h5:before {
    content:"*";
    color:#f43131

}
.LayerPopup .popup_grid th i:after {
    content: "*";
    color: #f43131
}
.LayerPopup .popup_grid th span {
    display: block;
    color: #f43131;
    margin-top: 4px;
    margin-left:0;
}
.LayerPopup .popup_grid .PopupDate > ul > li{
  position: relative;
}
.LayerPopup .popup_grid .PopupDate > ul > li .react-datepicker__triangle{
  left:50%!important
}