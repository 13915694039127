.grid_data {
  width: 100%;
  display: inline-block;
  margin-top: 4px;
  overflow-y: hidden;
}

.grid_data > div {
  min-width: 100%;
}

.grid_data .thead {
  background: #eef3f6;
  overflow: hidden;
  border-top: 2px solid #111;
  border-bottom: 1px solid #dcdcdc;
}

.grid_data .thead th {
  font-weight: normal;
  height: 60px;
}

.grid_data .tbody .hand {
  cursor: pointer;
}
.grid_data .tbody td.cnt {
  text-align: left;
  padding-left: 4%;
}
.grid_data .tbody.ScrollNone {
  max-height: inherit;
  overflow-x: hidden;
  overflow-y: hidden;
  resize: none;
  min-height: 120px;
}
.grid_data .tbody.HasTH th {
  background: #fafafa;
  font-weight: 400;
  border-bottom: 1px solid #dcdcdc;
}
.grid_data .tbody.HasTH tr.last td {
  border-bottom: 1px solid #dcdcdc;
}
.grid_data .tbody.HasRowsTH th {
  background: #fafafa;
  font-weight: 400;
  border-bottom: 1px solid #dcdcdc;
  height: 60px;
}
.grid_data .tbody.HasRowsTH th:first-child {
  text-align: left;
  padding-left: 7.6%;
}
.grid_data .tbody.HasRowsTH th + td {
  background: #fafafa;
  border-bottom: 1px solid #dcdcdc;
}
.grid_data .tbody.HasRowsTH th + td + td {
  background: #fafafa;
  border-bottom: 1px solid #dcdcdc;
}
.grid_data .tbody.HasRowsTH td.cnt {
  padding-left: 10.3%;
}
.grid_data .tbody.HasRowsTH tr.last td {
  border-bottom: 1px solid #dcdcdc;
}
.grid_data .tbody.NoreSize {
  max-height: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  min-height: inherit;
}

.grid_data .tbody td,
.grid_data .tfoot td {
  font-family: "Noto Sans KR", Sans-serif;
  font-weight: 400;
  color: #505050;
  height: 60px;
  text-align: center;
  border-bottom: 1px solid #ededed;
}

.grid_data .tbody td.amount,
.grid_data .tfoot td.amount {
  text-align: right;
  padding-right: 10px;
}

.grid_data .tbody td.tal,
.grid_data .tfoot td.tal {
  text-align: left;
  padding-left: 10px;
}

.grid_data .tbody td .string,
.grid_data .tfoot td .string {
  font-family: "Noto Sans KR", Sans-serif;
}

.grid_data .tbody td.date {
  background: #fafafa;
}
.grid_data .tbody td.NoData {
  height: 100px;
  font-weight: 500;
  font-size: 16px;
}
.grid_data .tbody.notice .noti td:first-child {
  color: #f43131;
  font-weight: 700;
}
.grid_data .tbody.notice .tit {
  text-align: left;
}
.grid_data .tbody.notice .tit {
  text-align: left;
}
.grid_data .tbody .TextColorRed {
  color: #f43131;
}
.grid_data .tbody td a.detail_btn:hover {
  text-decoration: underline;
}
.grid_data .tbody td a.detail_btn img {
  margin: -4px 0 0 5px;
}
.grid_data .thead.HasTD td {
  height: 60px;
  text-align: center;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-bottom: none;
}
.grid_data .thead.HasTD td:last-child {
  border-right: none;
}
@media all and (max-width: 1670px) {
  .grid_data .TableArea {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .grid_data .TableArea::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  .grid_data .TableArea::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 5px;
  }
  .grid_data .TableArea::-webkit-scrollbar-thumb {
    background: #b7b7b7;
    border-radius: 5px;
  }
  .grid_data .TableArea::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 5px;
  }
  .grid_data .TableArea .thead {
    width: 1500px;
  }
  .grid_data .TableArea .thead {
    width: 1500px;
  }
  .grid_data .TableArea .tbody {
    width: 1500px;
  }
  .grid_data .TableArea .tfoot {
    width: 1500px;
  }
}

/*Print Media Query*/
@media only print and (orientation: portrait) {

  .grid_data .TableArea .tbody, .grid_data .TableArea .tfoot, .grid_data .TableArea .thead{
    width:100%!important;
    max-width:1000px;
  }
}
@media only print and (orientation: landscape) {

  .grid_data .TableArea .tbody, .grid_data .TableArea .tfoot, .grid_data .TableArea .thead{
    width:100%!important
  }
}