.LoadingArea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  text-align: center;
  z-index: 20;
}
.LoadingArea h5 {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
}
.LoadingBG {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}
