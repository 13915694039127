.PlcfbInfo .condition_search .CustomInput {
  width: 160px;
  margin-right: 20px;
}
.PlcfbInfo .controls .button {
  margin-left: 10px;
}
.PlcfbInfo .PlcfbInfoPopup {
  width: 1160px;
}
.PlcfbInfo .LayerPopup .PopupArea .CustomInput {
  width: 100%;
}
.PlcfbInfo .LayerPopup .PopupArea .zipNo .CustomInput {
  width: 80px;
}
.PlcfbInfo .LayerPopup .PopupArea .select {
  width: 100%;
}
.PlcfbInfo .LayerPopup .PopupArea td.has_radio .radio + span {
  margin-right: 40px;
}
.PlcfbInfo .LayerPopup .PopupArea .bank .select {
  margin-bottom: 20px;
}
.PlcfbInfo .PlcfbInfoPopup .react-datepicker-wrapper {
  width: 100%;
}
.PlcfbInfo .PlcfbInfoPopup .react-datepicker__input-container > input {
  width: 100%;
}
.PlcfbInfo .LayerPopup .PopupArea .TextColorRed {
  color: #f43131;
}
.PlcfbInfo .LayerPopup .PopupArea .button.insert {
  margin-left: 10px;
}
.PlcfbInfo .LayerPopup .popup_grid .PopupDate ul li:first-child {
  margin-bottom: 10px;
}
