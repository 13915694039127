.DeliveryTxnStats .condition_search .CustomInput {
  width: 160px;
  margin-right: 20px;
}
.DeliveryTxnStats .condition_search .select {
  margin-right: 20px;
}
.DeliveryTxnStats .grid_data > div {
  min-width: 3000px;
}
.DeliveryTxnStats .grid_data .tbody {
  resize: none;
}
.DeliveryTxnStats .grid_data {
  resize: vertical;
}
