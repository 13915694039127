.UserInfo .condition_search .CustomInput {
  width: 150px;
}
.UserInfo .condition_search select {
  margin-right: 10px;
}
.UserInfo .condition_search input {
  margin-right: 10px;
}
.edit_select {
  width: 300px;
}
