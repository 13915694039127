.AcntDpsTxnListPopup .popup_grid td .CustomInput {
  width: 100%;
}

.AcntDpsTxnListPopup .popup_grid th,
.AcntDpsTxnListPopup .popup_grid td {
  padding: 0 10px;
  text-align: center;
}

.AcntDpsTxnListPopup .popup_grid {
  overflow-y: inherit;
}
