.TrmFeeTable .grid_data .tbody {
  resize: none;
}
.TrmFeeTable .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.TrmFeeTable .grid_data .tfoot td {
  color: #f43131;
}
.TrmFeeTable .grid_data .tfoot th {
  border-bottom: 1px solid #ededed;
  background: #eef3f9;
  font-weight: 400;
}

.TrmFeeTable .grid_data .holdTable { 
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;  
  overflow-x: hidden;
}