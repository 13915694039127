.AcntDpsTxnUpload .UploadArea {
  width: 100%;
  height: 300px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}
.AcntDpsTxnUpload .UploadArea.last {
  border-top-right-radius: 0;
}
.AcntDpsTxnUpload .UploadArea .drop {
  border: 1px dashed #b3b3b3;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0;
  background: #fafafa;
}

.AcntDpsTxnUpload .UploadArea .icon {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.AcntDpsTxnUpload .UploadArea h4 {
  font-weight: 400;
  margin-top: 15px;
}
.AcntDpsTxnUpload .UploadArea h4 span {
  color: #f43131;
}
.AcntDpsTxnUpload .UploadArea .drop.SecondStep {
  height: 100px;
  padding: 20px 40px;
  text-align: left;
}

.AcntDpsTxnUpload .UploadArea .drop.SecondStep .icon {
  display: inline-block;
  margin: inherit;
  margin-right: 40px;
}

.AcntDpsTxnUpload .UploadArea .drop.SecondStep .fee_file_upload {
  float: right;
}

.AcntDpsTxnUpload .UploadList {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  padding: 0 20px;
}

.AcntDpsTxnUpload .UploadList .file_sc {
  display: block;
  margin-bottom: 15px;
  color: #767676;
}

.AcntDpsTxnUpload .UploadList .file_name li {
  width: 33%;
  display: inline-block;
  margin: 15px 0;
  color: #a2a2a7;
  font-size: 12px;
}

.AcntDpsTxnUpload .UploadList .file_name img {
  margin-right: 5px;
  vertical-align: top;
}

.AcntDpsTxnUpload .UploadList .file_name span {
  font-size: 16px;
  color: #111;
  display: inline-block;
  width: 68.9%;
}

.AcntDpsTxnUpload .UploadList .file_name img.file_del_btn {
  margin-right: 0;
  margin-left: 10px;
  vertical-align: middle;
}
.AcntDpsTxnUpload .UploadArea .drop.ThirdStep .button {
  margin: 0 10px;
}
.AcntDpsTxnUpload .UploadArea .drop.ThirdStep h4 {
  font-weight: 400;
  margin-top: 15px;
  color: #f43131;
}
