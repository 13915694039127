.button {
  display: inline-block;
  line-height: 38px;
  padding: 0 19px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  min-width: 112px;
}

.button.insert {
  color: #fff;
  border: 1px solid #1a2f80;
  background: #1a2f80;
}
.button.inquire {
  color: #fff;
  border: 1px solid #1a2f80;
  background: #1a2f80;
  line-height: 54px;
  padding: 0 77px;
}
.button.inquire img {
  margin-right: 20px;
}

.button.update {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}
.button.data_delete {
  border: 1px solid #dcdcdc;
  background: #fff;
}
.button.delete {
  border: 1px solid #dcdcdc;
  background: #fff;
}
.button.save {
  color: #fff;
  border: 1px solid #1a2f80;
  background: #1a2f80;
}

.button.confirm {
  color: #fff;
  border: 1px solid #1a2f80;
  background: #1a2f80;
  padding: 0 42px;
}

.button.inquire {
  color: #fff;
  border: 1px solid #1a2f80;
  background: #1a2f80;
  min-width: inherit;
}

.button.file_upload {
  border: 1px solid #1a2f80;
  background: #1a2f80;
  color: #fff;
}

.button.complete {
  border: 1px solid #1a2f80;
  background: #1a2f80;
  color: #fff;
}

.button.modify {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}

.button.search {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}

.button.add_row {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}

.button.withdrawl_register {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}

.button.dbf_down {
  color: #1a2f80;
  border: 1px solid #1a2f80;
  background: #fff;
}
.button.dbf_down img {
  margin-right: 3px;
}

.button.all {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
}

.button.delete {
  border: 1px solid #dcdcdc;
  background: #fff;
}

.button.delete img {
  margin-right: 3px;
}

.button.prev {
  border: 1px solid #dcdcdc;
  background: #fff;
}

.button.prev img {
  margin-right: 3px;
}

.button.delete_row {
  border: 1px solid #dcdcdc;
  background: #fff;
}

.button.delete_row img {
  margin-right: 3px;
}

.button.cancel {
  border: 1px solid #dcdcdc;
  background: #fff;
  padding: 0 42px;
}

.button.close {
  border: 1px solid #dcdcdc;
  background: #fff;
  padding: 0 42px;
}

.button.excel {
  border: 1px solid #13a76f;
  background: #fff;
  color: #13a76f;
}

.button.excelMon {
  border: 1px solid #1a2f80;
  background: #fff;
  color: #1a2f80;
}

.button.withdrawl_rquest {
  border: 1px solid #1a2f80;
  background: #fff;
  color: #1a2f80;
}

.button.withdrawl_rquest img {
  margin-right: 3px;
  vertical-align: middle;
}
.button.add_file {
  border: 1px solid #dcdcdc;
  background: #fff;
  color: #111;
  padding: 0 72px;
  line-height: 52px;
  margin: 0;
}
.button.add_file img {
  margin-right: 3px;
  vertical-align: middle;
}
.button.fee_file_upload {
  border: 1px solid #1a2f80;
  background: #1a2f80;
  color: #fff;
  padding: 0 61px;
  line-height: 52px;
  margin: 0;
}
.button.fee_file_upload img {
  margin-right: 3px;
  vertical-align: middle;
}
.button.fee_file_del {
  border: none;
  background: none;
  color: #fff;
  padding: 0;
  line-height: 0;
  margin: 0;
  min-width: inherit;
  width: 9px;
  margin-left: 10px;
  vertical-align: middle;
}
.button.fee_file_del img {
  margin: 0;
}
.button.fee_file_del span {
  text-indent: -99999px;
}
.button.fee_clclt {
  border: 1px solid #5734c9;
  background: #5734c9;
  color: #fff;
  padding: 0 61px;
  line-height: 52px;
  margin: 0;
}
.button.fee_clclt img {
  margin-right: 3px;
  vertical-align: middle;
}
.button.ftas_clclt {
  border: 1px solid #5734c9;
  background: #5734c9;
  color: #fff;
  padding: 0 60px;
  line-height: 52px;
  margin: 0;
}
.button.ftas_clclt img {
  margin-right: 20px;
  vertical-align: middle;
}
.button.print {
  border: 1px solid #5734c9;
  background: #5734c9;
  color: #fff;
  padding: 0;
  line-height: 38px;
  margin: 0;
  min-width: 116px;
}
.button.print img {
  margin-right: 10px;
  vertical-align: middle;
}
.button.add {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
  min-width: inherit;
}
.button.data_armt {
  color: #fff;
  background: #5734c9;
  border: 1px solid #5734c9;
  padding: 0 69px;
  line-height: 52px;
}
.button.data_armt img {
  margin-right: 10px;
  vertical-align: middle;
}
.button.data_gnr {
  color: #fff;
  background: #5734c9;
  border: 1px solid #5734c9;
  padding: 0 69px;
  line-height: 52px;
}
.button.data_gnr img {
  margin-right: 10px;
  vertical-align: middle;
}
.button.send {
  border: 1px solid #00b4d8;
  background: #00b4d8;
  color: #fff;
  padding: 0 20px;
}

.button.send img {
  margin-right: 10px;
}
.button.dashboard {
  color: #1a2f80;
  border: 1px solid #fff;
  background: #fff;
  line-height: 42px;
  padding: 0;
  width: 130px;
  border-radius: 35px;
  font-weight: 500;
}
.button.dashboard img {
  margin-right: 8px;
}
.button.calc {
  color: #1a2f80;
  border: 1px solid #fff;
  background: #fff;
  line-height: 42px;
  padding: 0;
  width: 130px;
  border-radius: 35px;
  font-weight: 500;
}
.button.calc img {
  margin-right: 8px;
}
