.StepProcess {
  width: 100%;
  display: table;
  margin-bottom: 80px;
}

.StepProcess li {
  display: table-cell;
  text-align: center;
}

.StepProcess li:first-child {
  width: 19%;
}

.StepProcess li.arrow {
  width: 9%;
  vertical-align: middle;
}

.StepProcess span {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  line-height: 44px;
  font-size: 20px;
  color: #111;
  border: 1px solid #111;
  margin: auto;
  margin-bottom: 20px;
  font-weight: 500;
}

.StepProcess li.on span {
  background: #111;
  color: #fff;
}

.StepProcess li.on h3 {
  color: #1a2f80;
}

.StepProcess h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.StepProcess h4 {
  font-weight: 400;
  color: #505050;
  line-height: 20px;
}
