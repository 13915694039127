.Container {
  padding: 40px 0 40px 300px;
  width: 100%;
  display: inline-block;
}

.Content {
  padding-left: 3.8%;
  padding-right: 3.8%;
}

.Content .top_cnt {
  width: 100%;
}
