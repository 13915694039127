.AcntDpsTxnList .grid_data .tbody {
  resize: none;
}
.AcntDpsTxnList .grid_data.first {
  margin-bottom: 40px;
}
.AcntDpsTxnList .grid_data .holdTable { 
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;  
  overflow-x: hidden;
}