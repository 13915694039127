.AcruDclaTxn .grid_data .tbody {
  resize: none;
}
.AcruDclaTxn .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.AcruDclaTxn .grid_data .tbody.HasTH{
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;  
  overflow-x: hidden;
}