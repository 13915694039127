.SmsTrmHstTable .text {
  text-align: right;
  margin-bottom: 20px;
  font-size: 16px;
}
.SmsTrmHstTable td {
  position: relative;
}
.SmsTrmHstTable td.msg {
  text-align: left;
  padding-left: 40px;
}
.SmsTrmHstTable td.fail {
  color: #f43131;
}
.SmsTrmHstTable td.notSend {
  color: #336ee0;
}
.SmsTrmHstTable td div {
  display: none;
  position: absolute;
  border: 1px solid #b3b3b3;
  padding: 20px;
  width: 100%;
  max-width: 730px;
  background: #fff;
  left: 7.1%;
  top: 90%;
  border-radius: 10px;
  border-top-left-radius: 0;
  color: #111;
  line-height: 18px;
  z-index: 2;
}

.SmsTrmHstTable td div i {
  position: absolute;
  left: -1px;
  top: -16px;
}
.SmsTrmHstTable tbody tr:first-child div i {
  top: -15px;
}
.SmsTrmHstTable tbody tr:last-child div{
  border-bottom-left-radius:0;
  border-top-left-radius: 10px;
  top:-15%
}
.SmsTrmHstTable tbody tr:last-child div i {
  top: 46px;
  left:-10px;
  transform: rotate(-120deg);
}
.SmsTrmHstTable td:hover div {
  display: block;
}
