.checkbox {
    width: 18px;
    height: 18px;
    position: relative;
    background:#fff url(../../assets/images/icon_check1_off.png) center no-repeat;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #767676;
    border-radius: 3px
}

.checkbox input {
    opacity: 0;
    margin: 0;
    cursor: pointer
}

.checkbox.checked {
    background: url(../../assets/images/icon_check1_on.png) center no-repeat;
    background-color: #1a2f80;
    border: 1px solid #1a2f80
}
