.DpstTxnList .DpstTxnEnterPopup {
  width: 1188px;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid th {
  text-align: center;
  padding-left: 0;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid .first td {
  text-align: center;
  padding: 10px 0;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid .first tr:last-child td {
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td {
  padding: 10px;
  text-align: center;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td.tal {
  text-align: left;
  padding-left: 10px;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td .select {
  min-width: 100%;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td .CustomInput {
  width: 100%;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid .second {
  margin-top: 40px;
  border-top: 2px solid #111;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td.amount {
  text-align: right;
  padding-right: 10px;
}
.DpstTxnList .DpstTxnEnterPopup .popup_grid td.tal {
  text-align: left;
  padding-left: 10px;
}
