.IndvSmsMobile .mHeader {
  height: 90px;
  background-color: #1a2f80;
  text-align: center;
  line-height: 90px;
}
.IndvSmsMobile .mHeader img {
  width: 100%;
  max-width: 433px;
}
.IndvSmsMobile .mContents {
  padding: 0 20px;
}
.IndvSmsMobile .mContents h2 {
  padding: 72px 0;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  line-height: 50px;
}
.IndvSmsMobile .mContents h2 span {
  display: inline-block;
  font-weight: 500;
}
.IndvSmsMobile .mContents .info {
  border-top: 2px solid #111;
  border-bottom: 2px solid #111;
  width: 100%;
  margin-bottom: 40px;
}
.IndvSmsMobile .mContents .info dl {
  width: 100%;
  display: inline-block;
}
.IndvSmsMobile .mContents .info dt {
  width: 50%;
  float: left;
  text-align: left;
  font-size: 28px;
  color: #505050;
  line-height: 80px;
}
.IndvSmsMobile .mContents .info dd {
  width: 50%;
  float: left;
  text-align: right;
  font-size: 28px;
  line-height: 80px;
}
.IndvSmsMobile .mContents .info dl:first-child dt,
.IndvSmsMobile .mContents .info dl:first-child dd {
  border-bottom: 1px solid #dcdcdc;
}

.IndvSmsMobileTable .grid_data .thead th,
.IndvSmsMobileTable .grid_data .tbody td {
  font-size: 28px;
  height: 80px;
}
