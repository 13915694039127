.react-datepicker__input-container {
}

.react-datepicker__input-container > input {
  padding: 9px 0 9px 40px;
  border-width: 1px;
  border:1px solid #dcdcdc;
  border-radius: 10px;
  background: #fff url(../../assets/images/icon_calendar2.png) 14px center no-repeat;
}
