.FeeInfoHD .tab_area {
    width: 100%;
    margin-top: 100px;
    margin-bottom:40px
}
.FeeInfoHD .tab_area td {
    color: #767676;
    border-bottom: 1px solid #dcdcdc;
    text-align: center
}

.FeeInfoHD .tab_area td:first-child a {
    border-top-left-radius: 5px;
    border-left: 1px solid #dcdcdc
}

.FeeInfoHD .tab_area td.last a {
    border-top-right-radius: 5px
}

.FeeInfoHD .tab_area td a {
    display: block;
    padding: 23px 0;
    border-top: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    background: #fafafa;
}

.FeeInfoHD .tab_area td img {
    display: none;
    margin-right: 5px;
}

.FeeInfoHD .tab_area td.on {
    border-bottom: none
}

.FeeInfoHD .tab_area td.on a {
    background: #fff;
    color: #1a2f80;
    font-weight: 500
}

.FeeInfoHD .tab_area td.on img {
    display: inline-block
}