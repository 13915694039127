.prev {
  display: inline-block;
  cursor: pointer;
}

.prev img {
  margin: -3px 10px 0 0;
}

.line {
  display: inline-block;
  width: 1px;
  height: 12px;
  background: #dcdcdc;
  vertical-align: middle;
  margin: -3px 20px 0 20px;
}
