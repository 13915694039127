.RptDatPrtTerminal .grid_data .thead th {
  border-right: 1px solid #dcdcdc;
}
.RptDatPrtTerminal .grid_data .thead th:last-child {
  border-right: none;
}
.RptDatPrtTerminal .grid_data .thead td:last-child {
  border-right: none;
}
.RptDatPrtTerminal .grid_data .tbody td:first-child {
  background: #fafafa;
}
.RptDatPrtTerminal .grid_data.first .tbody td {
  font-family: "Roboto";
}
.RptDatPrtTerminal .grid_data .tfoot td {
  font-family: "Roboto";
  color: #f43131;
}
.RptDatPrtTerminal .grid_data .tfoot td:first-child {
  background: #eef3f9;
  color: #111;
}
.RptDatPrtTerminal .grid_data.first {
  margin-bottom: 100px;
}
/* .RptDatPrtTerminal .grid_data.second {
  border-top: 2px solid #111;
} */
.RptDatPrtTerminal .controls .button {
  margin-left: 10px;
}

.RptDatPrtTerminal .TotalInfo{
 width: 100%;
 background-color:#f7f7f7;
 margin-top:40px;
 border-radius:5px;
 text-align:center;
 padding: 35px 0;
}
.RptDatPrtTerminal .TotalInfo li{
  display:block;
  font-size:20px;
  font-weight:500;
  margin-left:20px;
  width:270px;
  margin:auto;
  text-align:left;
}
.RptDatPrtTerminal .TotalInfo li:first-child{
  margin-bottom: 12px;
}
.RptDatPrtTerminal .TotalInfo li span{
  color:#f43131;
  display:inline-block;
  width:54%;
  text-align:right;
}
.RptDatPrtTerminal .TotalInfo li img{
  display:none
}
.RptDatPrtTerminal .grid_data.second th h3{
  width:270px;
  text-align:left;
  margin:auto;
  line-height: 23px;
}
/*Print Media Query*/
@media only print and (orientation: portrait) {
  body {
    min-width: 1270px;
  }
  .Header,
  .Footer,
  .SideNavigation,
  .StepProcess,
  .title,
  .tab_area,
  .condition_search,
  .controls,
  h3.page_tit,
  .Navbar,
  .Nav {
    display: none;
  }

  .Container {
    padding-left: 0;
  }

  .Content {
    padding: 0;
  }
  .grid_data .TableArea .tbody,
  .grid_data .TableArea .tfoot,
  .grid_data .TableArea .thead {
    width: 100% !important;
  }
}
@media only print and (orientation: landscape) {
  body {
    min-width: 1440px;
  }
  .Header,
  .Footer,
  .SideNavigation,
  .StepProcess,
  .title,
  .tab_area,
  .condition_search,
  .controls,
  .Navbar,
  .Nav {
    display: none;
  }

  .Container {
    padding-left: 0;
  }

  .Content {
    padding: 0;
  }
  .grid_data .TableArea .tbody,
  .grid_data .TableArea .tfoot,
  .grid_data .TableArea .thead {
    width: 100% !important;
  }
  .controls{
    margin-bottom:0;
  }
  .controls.second{
    display: flex!important
  }
  h3.page_tit{
    margin-bottom: 10px;
  }
  .grid_data .thead th{
    height: 26px;
  }
  .grid_data .thead.HasTD td{
    height:26px;
  }
  .grid_data .tbody td, .grid_data .tfoot td{
    height:26px;
  }
  .RptDatPrtTerminal .grid_data.first{
    margin-bottom: 10px;
  }
  .RptDatPrtTerminal .TotalInfo{
    margin-top: 10px;
    padding:0;
  }
  .RptDatPrtTerminal .TotalInfo li:first-child{
    margin-bottom:7px;
  }
  .RptDatPrtTerminal .grid_data.second{
    margin-top:0;
  }
  .RptDatPrtTerminal .grid_data.second .thead th{
    padding-bottom: 20px;
    height: 60px;
  }
  .RptDatPrtTerminal .grid_data .tfoot td{
    color:#111
  }
  .grid_data .tbody td, .grid_data .tfoot td{
    color:#111
  }
  .RptDatPrtTerminal .TotalInfo li span{
    color:#111
  }
  .RptDatPrtTerminal .grid_data .tfoot td:first-child{
    background: #fff;
  }
  .RptDatPrtTerminal .grid_data .tbody td:first-child{
    background:#fff;
  }
  .grid_data .thead th{
    background:#fff;
  }
  .grid_data .tbody.HasRowsTH th, .grid_data .tbody.HasTH th, .grid_data .tbody.HasTH tr.last td{
    border-bottom:none;
  }
  .grid_data .tbody.HasTH th{
    background: #fff;
  }
  .grid_data .tbody.HasTH td{
    border-bottom:none;
  }
  .grid_data .tbody.NoreSize{
    border:1px solid #000
  }
  .RptDatPrtTerminal .grid_data .thead th{
    border:1px solid #000
  }
  .grid_data .thead.HasTD td{
    border:1px solid #000
  }
  .RptDatPrtTerminal .grid_data .thead td:last-child{
    border-right:1px solid #000
  }
  .grid_data .tbody td.amount{
    border-right:1px solid #000
  }
 .RptDatPrtTerminal .grid_data.first td.amount{
    border-bottom: 1px solid #000;
  }
  .RptDatPrtTerminal .grid_data .tbody td:first-child{
    border-left:1px solid #000;
    border-right:1px solid #000
  }
  .RptDatPrtTerminal .grid_data .tfoot td{
    border-right:1px solid #000;
    border-bottom: 1px solid #000;
  }
  .RptDatPrtTerminal .grid_data .tfoot td:first-child{
    border-left:1px solid #000
  }
   .grid_data.second .tbody td.amount{
     border:none
   }
   .grid_data.second .tbody tr:first-child td.amount,.grid_data.second .tbody tr:last-child td.amount{
    border-top:none
  }
   .RptDatPrtTerminal .grid_data .tbody td:first-child{
     border-bottom:1px solid #000;
   }
   .RptDatPrtTerminal .grid_data .thead td:last-child, .RptDatPrtTerminal .grid_data .thead th:last-child{
     border-right:1px solid #000
   }
}
