.CustomTextArea {
    border: 1px solid #dcdcdc;
    padding: 9px 19px;
    border-radius: 10px;
    width:100%;
    min-height:220px;
    resize:none
}
.CustomTextArea::placeholder {
    color: #767676
}

.CustomTextArea:-ms-input-placeholder {
    color: #767676
}

.CustomTextArea:focus {
    border: 1px solid #1a2f80
}
