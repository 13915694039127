.RoleMgtEdit form {
  width: 100%;
}
.RoleMgtEdit .controls i::after {
  content: "*";
  color: #f43131;
}
.RoleMgtEdit .controls span {
  /* display: block; */
  color: #f43131;
  margin-top: 4px;
}
.RoleMgtEdit .controls .CustomInput {
  width: 262px;
}
.RoleMgtEdit .controls .button {
  margin-left: 10px;
}
.RoleMgtEdit .controls .button.save {
  min-width: 64px;
}
/* .RoleMgtEdit .grid_data .thead th:first-child {
  text-align: left;
  padding-left: 12%;
} */
