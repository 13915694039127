.FareFeeTxnPopupBatch .FareFeeTxnPopup {
  width: 1315px;
}
.FareFeeTxnPopupBatch .popup_grid {
  width: 1315px;
}
.FareFeeTxnPopupBatch .popup_grid th {
  text-align: center;
  padding-left: 0;
}
.FareFeeTxnPopupBatch .popup_grid .first {
  max-height: 242px;
  overflow-y: auto;
  resize: vertical;
}
.FareFeeTxnPopupBatch .popup_grid .first td {
  text-align: center;
  padding: 10px 0;
}
.FareFeeTxnPopupBatch .popup_grid .first td.amount {
  text-align: right;
  padding-right: 10px;
}
.FareFeeTxnPopupBatch .popup_grid .first tr:last-child td {
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.FareFeeTxnPopupBatch .popup_grid .first tr:last-child td.amount {
  text-align: right;
  padding-right: 10px;
}
.FareFeeTxnPopupBatch .popup_grid td {
  padding: 10px;
  text-align: center;
}
.FareFeeTxnPopupBatch .popup_grid td .select {
  min-width: 100%;
}
.FareFeeTxnPopupBatch .popup_grid td .CustomInput {
  width: 100%;
}
.FareFeeTxnPopupBatch .popup_grid .second {
  margin-top: 40px;
  border-top: 2px solid #111;
}
