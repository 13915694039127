.paging {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 40px 0;
  font-family: roboto;
}

.paging ul {
  display: inline-block;
}

.paging li {
  display: inline-block;
}

.paging li span {
  color: #505050;
  display: block;
  height: 24px;
  padding:0 11px;
  line-height: 22px;
  cursor: pointer;
}

.paging li.on span {
  color: #1a2f80;
  border: 1px solid #1a2f80;
}
