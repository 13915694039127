.Autocomplete .CacheAutocomplete input {
  border: 1px solid #dcdcdc;
  padding: 9px 19px;
  border-radius: 10px;
}
.Autocomplete .CacheAutocomplete > div {
  font-size: 14px;
  padding: 10px 0 10px 20px;
  max-height: 200px;
  border-radius: 0;
  border: 1px solid #111;
}
.Autocomplete .CacheAutocomplete > div > div {
  padding: 3px 0;
}

input[role="combobox"] {
  width: 100%;
  border: 1px solid #dcdcdc;
  padding: 9px 19px;
  border-radius: 10px;
}
.Autocomplete > div {
  position: relative;
}
.Autocomplete .renderMenu {
  position: absolute;
  max-height: 61px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  padding: 3px;
  background-color: #fff;
  z-index: 10;
}
