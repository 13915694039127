.SetlSumTxn .SetlSumTxnEnterPopup {
  width: 1120px;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid {
  border-bottom: none;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid th {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB {
  max-height: 245px;
  overflow-x: hidden;
  overflow-y: auto;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB table {
  border-top: 2px solid #111;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid td {
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #dcdcdc !important;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid td.amount {
  text-align: right;
  padding-right: 10px;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid td.tal {
  text-align: left;
  padding-left: 10px;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .btn_area {
  text-align: right;
  margin-top: 40px;
  margin-bottom: 10px;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB button {
  width: 90% !important;
  min-width: 90% !important;
  padding: 0;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB input[type="text"] {
  width: 90% !important;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB input[type="number"] {
  width: 90% !important;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB .select {
  width: 90% !important;
  min-width: 90% !important;
}
.SetlSumTxn .SetlSumTxnEnterPopup .popup_grid .secondTB .RegID {
  color: #f43131;
}
