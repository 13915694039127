.ReadyMoneyTxnDepositTable .grid_data .thead th {
  border-right: 1px solid #dcdcdc;
}
.ReadyMoneyTxnDepositTable .grid_data .thead th:last-child {
  border-right: none;
}
.ReadyMoneyTxnDepositTable .grid_data .tbody.HasTH th {
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.ReadyMoneyTxnDepositTable .grid_data .tfoot td {
  color: #f43131;
}
.ReadyMoneyTxnDepositTable .grid_data .tfoot th {
  border-bottom: 1px solid #ededed;
  background: #eef3f9;
  font-weight: 400;
}
