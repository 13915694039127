.PlcfbAbookTxn .condition_search .CustomInput {
  width: 160px;
  margin-right: 20px;
}
.PlcfbAbookTxn .condition_search .select {
  margin-right: 20px;
}

.PlcfbAbookTxn .controls .button {
  margin-left: 10px;
}

.PlcfbAbookTxn .grid_data.first > div {
  min-width: 2820px;
}

.PlcfbAbookTxn .grid_data.first {
  resize: horizontal;
}
.PlcfbAbookTxn .grid_data.first .tbody {
  max-height: 300px;
}
.PlcfbAbookTxn .grid_data.first td input {
  width: 90%;
}
.PlcfbAbookTxn .grid_data.first .tbody.NoreSize tr.nouse > th,
.PlcfbAbookTxn .grid_data.first .tbody.NoreSize tr.nouse > td {
  color: #a6a6a6;
}
.PlcfbAbookTxn .tab_area {
  width: 100%;
  margin: 60px 0 40px 0;
}
.PlcfbAbookTxn .grid_data .thead th {
  line-height: 18px;
  white-space: pre-wrap;
}
.PlcfbAbookTxn .tab_area td {
  color: #767676;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}
.PlcfbAbookTxn .grid_data .tbody.HasTH th {
  border-bottom: 1px solid #ededed;
}
.PlcfbAbookTxn .tab_area td:first-child a {
  border-top-left-radius: 5px;
  border-left: 1px solid #dcdcdc;
}
.PlcfbAbookTxn .tab_area td.last a {
  border-top-right-radius: 5px;
}
.PlcfbAbookTxn .tab_area td a {
  display: block;
  padding: 23px 0;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  background: #fafafa;
}
.PlcfbAbookTxn .tab_area td img {
  display: none;
  margin-right: 5px;
}
.PlcfbAbookTxn .tab_area td.on {
  border-bottom: none;
}
.PlcfbAbookTxn .tab_area td.on a {
  background: #fff;
  color: #1a2f80;
  font-weight: 500;
}
.PlcfbAbookTxn .tab_area td.on img {
  display: inline-block;
}

.PlcfbAbookTxn .text_info {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.PlcfbAbookTxn .text_info p {
  color: #505050;
  font-weight: 300;
  position: relative;
  padding-left: 12px;
  margin-top: 12px;
}
.PlcfbAbookTxn .text_info p:before {
  content: "";
  width: 4px;
  height: 4px;
  background: #929298;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.PlcfbAbookTxn .text_info p span {
  color: #f43132;
}

.PlcfbAbookTxn .baseDateArea {
  position: relative;
}
.PlcfbAbookTxn .baseDateArea .react-datepicker__input-container > input {
  width: 256px;
}

.PlcfbAbookTxn .baseDateArea .button.save {
  position: absolute;
  top: 0;
  left: 317px;
}
