.TrmSumSheetTable .grid_data .tbody {
    resize: none
}
.TrmSumSheetTable .grid_data .tbody.HasTH th {
    background: #fafafa;
    border-bottom: 1px solid #ededed;
}
.TrmSumSheetTable .grid_data .tfoot td {
    color: #f43131;
}
.TrmSumSheetTable .grid_data .tfoot th {
    border-bottom: 1px solid #ededed;
    background: #eef3f9;
    font-weight:400
}