.SmsTrmTxn .SmsSelect {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;
  background: #fff;
}

.SmsTrmTxn .SmsSelect.basic {
  height: 300px;
}
.SmsTrmTxn .SmsSelect.other {
  height: 220px;
}

.SmsTrmTxn .SmsSelect .Type {
  border: 1px dashed #b3b3b3;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0;
  background: #fafafa;
}

.SmsTrmTxn .SmsSelect .SelectArea {
  display: block;
  width: 410px;
  text-align: left;
  margin: auto;
  margin-bottom: 20px;
}
.SmsTrmTxn .SmsSelect .SelectArea dt {
  display: inline-block;
  color: #111;
  font-weight: 500;
  margin-right: 20px;
}
.SmsTrmTxn .SmsSelect .SelectArea dd {
  display: inline-block;
}
.SmsTrmTxn .SmsSelect .SelectArea dd .select {
  width: 262px;
}
.SmsTrmTxn .SmsSelect h4 {
  font-weight: 400;
  margin-top: 15px;
}
.SmsTrmTxn .SmsSelect h4 span {
  color: #f43131;
}

.SmsTrmTxn .SmsTrmTxnTable .topArea {
  margin: 40px 0 20px 0;
  text-align: right;
  display: inline-block;
  width: 100%;
}
.SmsTrmTxn .SmsTrmTxnTable .topArea h3 {
  font-size: 20px;
  font-weight: 500;
  float: left;
  margin-top: 18px;
}
.SmsTrmTxn .SmsTrmTxnTable .topArea .button {
  margin-left: 10px;
}
.SmsTrmTxn .SmsTrmTxnTable td {
  position: relative;
}
.SmsTrmTxn .SmsTrmTxnTable td.msg {
  text-align: left;
  padding-left: 40px;
}
.SmsTrmTxn .SmsTrmTxnTable td div {
  display: none;
  position: absolute;
  border: 1px solid #b3b3b3;
  padding: 20px;
  width: 100%;
  max-width: 730px;
  background: #fff;
  left: 5.4%;
  top: 90%;
  border-radius: 10px;
  border-top-left-radius: 0;
  color: #111;
  line-height: 18px;
  z-index: 2;
}
.SmsTrmTxn .SmsTrmTxnTable td div i {
  position: absolute;
  left: -1px;
  top: -16px;
}
.SmsTrmTxn .SmsTrmTxnTable td:hover div {
  display: block;
}

.SmsTrmTxn .UploadArea {
  width: 100%;
  height: 300px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}
.SmsTrmTxn .UploadArea.last {
  border-top-right-radius: 0;
}
.SmsTrmTxn .UploadArea .drop {
  border: 1px dashed #b3b3b3;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0;
  background: #fafafa;
}

.SmsTrmTxn .UploadArea .icon {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.SmsTrmTxn .UploadArea h4 {
  font-weight: 400;
  margin-top: 15px;
}
.SmsTrmTxn .UploadArea h4 span {
  color: #f43131;
}
.SmsTrmTxn .UploadArea .drop.SecondStep {
  height: 100px;
  padding: 20px 40px;
  text-align: left;
}

.SmsTrmTxn .UploadArea .drop.SecondStep .icon {
  display: inline-block;
  margin: inherit;
  margin-right: 40px;
}

.SmsTrmTxn .UploadArea .drop.SecondStep .fee_file_upload {
  float: right;
}

.SmsTrmTxn .SmsSelect .UploadList {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  padding: 0 20px;
}

.SmsTrmTxn .SmsSelect .UploadList .file_sc {
  display: block;
  margin-bottom: 15px;
  color: #767676;
}

.SmsTrmTxn .SmsSelect .UploadList .file_name li {
  width: 33%;
  display: inline-block;
  margin: 15px 0;
  color: #a2a2a7;
  font-size: 12px;
}

.SmsTrmTxn .SmsSelect .UploadList .file_name img {
  margin-right: 5px;
  vertical-align: top;
}

.SmsTrmTxn .SmsSelect .UploadList .file_name span {
  font-size: 16px;
  color: #111;
  display: inline-block;
  width: 68.9%;
}

.SmsTrmTxn .SmsSelect .UploadList .file_name img.file_del_btn {
  margin-right: 0;
  margin-left: 10px;
  vertical-align: middle;
}
.SmsTrmTxn .UploadArea .drop.ThirdStep .button {
  margin: 0 10px;
}
.SmsTrmTxn .UploadArea .drop.ThirdStep h4 {
  font-weight: 400;
  margin-top: 15px;
  color: #f43131;
}
.SmsTrmTxn .baseDate{
  margin:10px auto;
  margin-top:-10px;

}