.DateSelectArea{
    position:relative;
    display:inline-block
}
.DateSelectArea:after{
    content:'';
    width:8px;
    height:6px;
    position:absolute;
    right:10px;
    top:50%;
    transform:translateY(-50%);
    background: url(../../assets/images/down_arrow_gray.png) no-repeat;
}
.CustomInputDate {
    border: 1px solid #dcdcdc;
    padding: 9px 30px 9px 50px;
    border-radius: 10px;
    background: #fff url(../../assets/images/icon_calendar2.png) 20px center no-repeat;
}
.CustomInputDate::placeholder {
    color: #767676
}

.CustomInputDate:-ms-input-placeholder {
    color: #767676
}

.CustomInputDate:focus {
    border: 1px solid #1a2f80
}