
.PlcfbAbookTxnImpossList .condition_search {
    padding: 40px;
    margin-bottom: 40px
}
.PlcfbAbookTxnImpossList .condition_search .CustomInput {
    width: 160px;
    margin-right: 20px
}
.PlcfbAbookTxnImpossList .condition_search .select{
    margin-right:20px
}
.PlcfbAbookTxnImpossList .controls .button{
    margin-left:10px
}


