
.Notification .condition_search input {
  margin-right: 20px;
}

.Notification .Popup .TextContent {
  max-height: 500px;
  min-height: 200px;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 14px;
  white-space: pre;
}

.Notification .grid_data .tbody td.tal{
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}


.Notification .Popup {
  width: 1400px;
  height: 720px;
}

.Notification .Popup .TextContent {
  max-height: 391px;
}

.Notification .LayerPopup .popup_grid {
  max-height: 595px;
}

.Notification .LayerPopup .popup_grid .PopupDate {
  width: 45%;
  margin-top: 0;
}

.Notification .LayerPopup .popup_grid tr:last-child th {
  height: 412px;
}

.Notification .CustomTextArea {
  height: 430px;
}



