.SalesSumTxnTable .grid_data .tbody {
    resize: none
}
.SalesSumTxnTable .grid_data .thead th:first-child {
    border-left: none
}
.SalesSumTxnTable .grid_data .tbody.HasTH th {
    background: #fafafa;
    border-bottom: 1px solid #ededed;
}
.SalesSumTxnTable .grid_data .tfoot tr:first-child td {
    color: #f43131;
}
.SalesSumTxnTable .grid_data .tfoot td {
    color: #336ee0
}
.SalesSumTxnTable .grid_data .tfoot th img {
    margin-right:5px
}
.SalesSumTxnTable .grid_data .tfoot th {
    border-bottom: 1px solid #ededed;
    background: #eef3f9;
    font-weight: 400;
}
.SalesSumTxnTable .grid_data .holdTable {
  max-height: 360px;
  width: 100%;
  overflow-y: scroll; 
  /* -ms-overflow-style: none;   */
}

/* .SalesSumTxnTable .grid_data .holdTable::-webkit-scrollbar { 
  display: none !important; 
} */