.LoginWrap {
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: block;
  background: url(../../../assets/images/login/login_bg.jpg) no-repeat center
    center;
  background-size: cover;
}
.area {
  width: 1240px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: right;
}
.area:before {
  content: "";
  width: 1px;
  height: 320px;
  border-right: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.LoginLogo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 530px;
}
.LoginLogo img {
  width: 100%;
}
form {
  width: 440px;
  display: inline-block;
  text-align: left;
}
form li {
  width: 100%;
  display: inline-block;
}
.LoginInput {
  margin: 10px 0;
}
.LoginInput input {
  width: 100%;
  background: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 18px 20px;
}
.LoginCheck {
  margin-bottom: 20px;
  color: #fd2931;
}
.LoginButton {
  margin-top: 10px;
}
.LoginButton button {
  width: 100%;
  line-height: 60px;
  text-align: center;
  border: none;
  background: #1a2f80;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.LoginSave {
  margin: 10px 0;
  line-height: 22px;
}
.LoginSave span {
  width: 22px;
  height: 22px;
  display: inline-block;
  background: url(../../../assets/images/login/LoginContinuing_check_off.svg)
    no-repeat center center;
  cursor: pointer;
  margin-right: 5px;
}
.LoginSave span input {
  opacity: 0;
  cursor: pointer;
}
.LoginSave span.on {
  background: url(../../../assets/images/login/LoginContinuing_check_on.svg)
    no-repeat center center;
}
.LoginLost {
  margin-top: 25px;
  position: relative;
  padding-left: 10px;
  color: #505050;
}
.LoginLost:before {
  content: "";
  width: 2px;
  height: 2px;
  background: #505050;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.FooterText {
  width: 100%;
  bottom: 20px;
  left: 0;
  text-align: center;
  position: fixed;
  font-weight: 500;
}
.error {
  margin-top: 10px;
  color: #fd2931;
}
