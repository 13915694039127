.SetlTxnCorrectionContent h4.title {
  font-size: 20px;
  font-weight: 500;
  margin: 65px 0 20px 0;
}

.SetlTxnCorrectionContent .grid_data .impossible,
.SetlTxnCorrectionContent .grid_data .necessary {
  color: #f43131;
}
.SetlTxnCorrectionContent .grid_data .tbody {
  resize: none;
}
.SetlTxnCorrectionContent .text_info {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.SetlTxnCorrectionContent .text_info p {
  color: #505050;
  font-weight: 300;
  position: relative;
  padding-left: 12px;
  margin-top: 12px;
}
.SetlTxnCorrectionContent .text_info p:before {
  content: "";
  width: 4px;
  height: 4px;
  background: #929298;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.SetlTxnCorrectionContent .text_info p > span {
  color: #f43132;
}
.SetlTxnCorrectionContent .text_info p > span.line {
  width: 1px;
  height: 10px;
  background: #767676;
}
.SetlTxnCorrectionContent .text_info p > img.line {
  margin: -2px 5px 0 5px;
}
.SetlTxnCorrectionContent .text_info p > img {
  margin-top: -2px;
}
.SetlTxnCorrectionContent .text_info a > span {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 1px solid #111;
  color: #111;
}
.SetlTxnCorrectionContent .text_info a > img {
  margin: -2px 0 0 8px;
}
.SetlTxnCorrectionContent .PlcfbInfo .PlcfbInfoPopup {
  width: 1160px;
}
.SetlTxnCorrectionContent .PlcfbInfo .LayerPopup .PopupArea .CustomInput {
  width: 331px;
}
.SetlTxnCorrectionContent .PlcfbInfo .LayerPopup .PopupArea .select {
  width: 100%;
}
.SetlTxnCorrectionContent
  .PlcfbInfo
  .LayerPopup
  .PopupArea
  td.has_radio
  .radio
  + span {
  margin-right: 40px;
}
.SetlTxnCorrectionContent .PlcfbInfo .LayerPopup .PopupArea .bank .select {
  margin-bottom: 20px;
}
.SetlTxnCorrectionContent .PlcfbInfo .PlcfbInfoPopup .react-datepicker-wrapper {
  width: 100%;
}
.SetlTxnCorrectionContent
  .PlcfbInfo
  .PlcfbInfoPopup
  .react-datepicker__input-container
  > input {
  width: 100%;
}
.SetlTxnCorrectionContent .PlcfbInfo .LayerPopup .PopupArea .TextColorRed {
  color: #f43131;
}
.SetlTxnCorrectionContent .PlcfbInfo .LayerPopup .PopupArea .button.insert {
  margin-left: 10px;
}
.SetlTxnCorrectionContent
  .PlcfbInfo
  .LayerPopup
  .popup_grid
  .PopupDate
  ul
  li:first-child {
  margin-bottom: 10px;
}
.SetlTxnCorrectionContent .CorrectionPopup {
  width: 1160px;
}
