.IndvSmsTrmTxn .EnterMessage {
  width: 375px;
  height: 669px;
  background: url(../../../assets/images/cellphone_bg.png) no-repeat center
    center;
  background-size: cover;
  padding: 81px 32px 0 31px;
}
.IndvSmsTrmTxn .EnterMessage .textArea {
  padding: 0 11px;
  width: 100%;
  height: 395px;
  margin-bottom: 30px;
}
.IndvSmsTrmTxn .EnterMessage .textArea textarea {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 15px;
  resize: none;
}
.IndvSmsTrmTxn .EnterMessage .textArea textarea::placeholder {
  color: #767676;
  opacity: 1;
}

.IndvSmsTrmTxn .EnterMessage .textArea textarea::-webkit-input-placeholder {
  color: #767676;
}

.IndvSmsTrmTxn .EnterMessage .textArea textarea:-ms-input-placeholder {
  color: #767676;
}

.IndvSmsTrmTxn .EnterMessage .textArea textarea:-mos-input-placeholder {
  color: #767676;
}

.IndvSmsTrmTxn .EnterMessage dl {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.IndvSmsTrmTxn .EnterMessage dt {
  font-weight: 500;
  margin-right: 22px;
  display: inline-block;
}
.IndvSmsTrmTxn .EnterMessage dd {
  display: inline-block;
}
.IndvSmsTrmTxn .EnterMessage dd input {
  width: 200px;
}
.IndvSmsTrmTxn .EnterMessage .button.send {
  width: 100%;
  line-height: 54px;
}
.IndvSmsTrmTxn .EnterMessage dl dt i::after {
  content: "*";
  color: #f43131;
}
.IndvSmsTrmTxn .EnterMessage dl dt span {
  display: block;
  color: #f43131;
  margin-top: 4px;
}
.IndvSmsTrmTxn .EnterMessage span {
  display: block;
  color: #f43131;
  margin-bottom: 4px;
}
