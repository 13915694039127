.ReadyMoneyTxn .condition_search table td dd.Double {
  position: relative;
}
.ReadyMoneyTxn .condition_search table td dl {
  width: 40%;
}
.ReadyMoneyTxn .condition_search table td:first-child dl:first-child {
  width: 50%;
}
.ReadyMoneyTxn .condition_search table td:first-child dl:first-child dd {
  padding-right: 2.7%;
}
.ReadyMoneyTxn .condition_search table td dl .react-datepicker-wrapper {
  width: 47.7%;
}
.ReadyMoneyTxn
  .condition_search
  table
  td
  dl
  .react-datepicker-wrapper
  .react-datepicker__input-container
  > input {
  width: 100%;
  padding-left: 30px;
  background-position-x: 7px;
}
.ReadyMoneyTxn .condition_search .react-datepicker__tab-loop:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 17% !important;
}
.ReadyMoneyTxn .controls .button {
  margin-left: 10px;
}
.ReadyMoneyTxn .controls .button.add {
  color: #fff;
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
  min-width: 112px;
}
.ReadyMoneyTxn .grid_data .tbody input[type="text"] {
  width: 90%;
}
@media all and (max-width: 1590px) {
  .ReadyMoneyTxn .condition_search table td:first-child {
    width: 70%;
  }
  .ReadyMoneyTxn .condition_search table td dl {
    width: 50%;
  }
}
@media all and (max-width: 1450px) {
}
