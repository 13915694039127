/* 
.controls {
  width: 100%;
  position: relative;
  text-align: right;
  margin-bottom: 16px;
}

.select_list {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
 */

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.controls h6{
    font-size:14px;
    font-weight:500;
    display:inline-block;
    line-height:40px;
    margin-right:20px
}
